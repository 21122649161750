import {
  TxGrpcApi,
  TokenStatic,
  ChainRestWasmApi,
  TokenFactoryStatic,
  IndexerGrpcArchiverApi,
  IndexerGrpcTransactionApi
} from '@injectivelabs/sdk-ts'
import { ENDPOINTS, IS_MAINNET, IS_TESTNET } from '@shared/utils/constant'
import { tokens } from '@/app/json'

export const tokenFactoryStatic = new TokenFactoryStatic(
  tokens as TokenStatic[]
)

export const chainRestWasmApi = new ChainRestWasmApi(ENDPOINTS.rest)

export const txService = new TxGrpcApi(ENDPOINTS.grpc)

export const indexerGrpcTransactionApi = new IndexerGrpcTransactionApi(
  ENDPOINTS.indexer
)

export const indexerGrpcArchiverApi = new IndexerGrpcArchiverApi(
  IS_MAINNET
    ? 'https://k8s.mainnet.archiver.grpc-web.injective.network'
    : IS_TESTNET
      ? 'https://k8s.testnet.archiver.grpc-web.injective.network'
      : ENDPOINTS.indexer
)
